import * as React from "react";
import * as style from "./pageHeader.module.scss";
import { Button, Tag } from "antd";
import { PageHeader as AntPageHeader } from "antd";
import { PageHeaderProps as AntPageHeaderProps } from "antd/es/page-header";

interface PageHeaderProps extends AntPageHeaderProps {}

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { ...otherProps } = props;
  return (
    <div className={style.pageHeader}>
      <AntPageHeader {...otherProps}>{props.children}</AntPageHeader>
    </div>
  );
};
