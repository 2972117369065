import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "src/store";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { Provider } from "react-redux";
import { firebase, rrfConfig } from "src/store/reducer/firebase";
import { createFirestoreInstance } from "redux-firestore";
import { App } from "src/App";
import { createBrowserHistory } from 'history';


// This is where the global scss is imported
import 'src/scss/main.scss';


const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export const AppBridge: React.FC = () => (
  <Router>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </Router>
);
