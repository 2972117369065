import * as React from "react";
import * as style from "./header.module.scss";
import { Logo } from "src/components/Logo/Logo";
import { Avatar, Dropdown, Menu } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { UserRepo } from "src/models/repositories/user";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { LOGIN } from "src/constants/route";
import { useHistory } from "react-router-dom";

export interface HeaderProps {
  onClickLogo?: () => void;
  showHeaderRightSection?: boolean;
}

export const Header: React.FC<HeaderProps> = (props) => {
  const { onClickLogo, showHeaderRightSection = true } = props;

  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const userRepo = new UserRepo(firebase, firestore);
  const menu = (
    <Menu>
      <Menu.Item
        onClick={async () => {
          await userRepo.logoutUser();
          history.push(LOGIN);
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`${style.header} d-flex align-items-center w-100 justify-content-between`}
    >
      <Logo className={style.logo} onClick={onClickLogo} />
      {showHeaderRightSection && (
        <Dropdown overlay={menu}>
          <div
            className={`d-flex align-items-center justify-content-center ${style.avatar}`}
          >
            <div className={`mx-1`}>Account</div>
            <DownOutlined className={style.downIcon} />
          </div>
        </Dropdown>
      )}
    </div>
  );
};
