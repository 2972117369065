export const isDateMoreThanNow = (seconds: number) => {
  if (!seconds) {
    return;
  }
  const date = new Date(1970, 0, 1);
  date.setTime(seconds * 1000);
  const now = new Date();
  if (date > now) {
    return date;
  }
};
