import * as React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import { FirebaseReducer, isEmpty, isLoaded } from "react-redux-firebase";
import { IUser } from "src/models/types";
import { useParams, useHistory } from "react-router-dom";
import { LOGIN } from "src/constants/route";
import { message, Spin } from "antd";

interface RouteForAssessorProps {
  children: any;
}

export const RouteForAssessor: React.FC<RouteForAssessorProps> = (
  props: RouteForAssessorProps
) => {
  const { children } = props;
  const history = useHistory();
  const { project_slug } = useParams();

  const profile: any = useSelector<
    StoreInterface,
    FirebaseReducer.Profile<{}> | IUser
  >((state) => state.firebase.profile);

  const isAssessor = (profile?.assessorProjectIds || []).some((x) => x === project_slug) || false;

  return isLoaded(profile) ? (
    !isEmpty(profile) && isAssessor ? (
      children
    ) : (
      <>
        {message.warn({
          content:
            "Unauthorized. Please login to with the correct credentials.",
          key: "unauthorized",
        })}
        {history.push(LOGIN)}
      </>
    )
  ) : (
    <div className={`d-flex h-100 justify-content-center align-items-center`}>
      <Spin tip="Loading..." />
    </div>
  );
};
