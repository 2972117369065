export const collections = {
  assessors: 'assessors',
  candidates: 'candidates',
  materials: 'materials',
  rooms: 'rooms',
  projects: 'projects',
  users: 'users',
};

export enum CandidateStatuses {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  VIEWING = "VIEWING",
}
