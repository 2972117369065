import * as React from "react";
import * as style from "./pageBase.module.scss";
import { Card } from "antd";
import { Header, HeaderProps } from "src/components/Header/Header";
import {
  useHistory,
  useParams,
  withRouter,
  useLocation,
} from "react-router-dom";
import { TopMenu } from "src/components/SidebarMenu/TopMenu";
import generatePath from "src/utils/regex";
import { ASSESSOR_ROOMS, CANDIDATE_LANDING } from "src/constants/route";

interface PageBaseProps extends HeaderProps {
  isAssessor?: boolean;
}

export const PageBase: React.FC<PageBaseProps> = (props) => {
  const { isAssessor = true, children, ...otherProps } = props;

  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const onClickLogo = () => {
    history.push({
      pathname: generatePath(isAssessor ? ASSESSOR_ROOMS : CANDIDATE_LANDING, {
        ...params,
      }),
      search: location.search,
    });
  };

  return (
    <div className={`${style.pageBase}`}>
      <div className={`d-flex flex-column w-100 h-100 ${style.divContent}`}>
        <Header {...otherProps} onClickLogo={onClickLogo} {...otherProps} />
        <Card className={`${style.content}`}>
          {isAssessor && <TopMenu />}
          {props.children}
        </Card>
      </div>
    </div>
  );
};
