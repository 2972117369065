import {init, RematchDispatch, RematchRootState} from "@rematch/core";
import * as models from "src/store/models/countModel";
import {FirebaseReducer, firebaseReducer , FirestoreReducer} from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';


export const store = init({
  models,
  redux: {
    reducers: {
      firebase: firebaseReducer,
      firestore: firestoreReducer,
    },
  },
});

export type Store = typeof store
// export type Dispatch = RematchDispatch<RootModel>
// export type iRootState = RematchRootState<RootModel>

export interface StoreInterface extends Store {
  firebase: FirebaseReducer.Reducer;
  firestore: FirestoreReducer.Reducer;
}
