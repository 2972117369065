import * as React from "react";
import * as style from "./logo.module.scss";

interface LogoProps {
  onClick?: () => void;
  className?: string;
}

export const Logo: React.FC<LogoProps> = (props) => {
  return (
    <div className={`${style.logo} ${props.className}`} onClick={props.onClick}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="184.000000pt" height="152.000000pt" viewBox="0 0 184.000000 152.000000"
           preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,152.000000) scale(0.100000,-0.100000)"
           fill="#000000" stroke="none">
          <path fill="#0b4b7f" d="M783 1505 c-159 -36 -281 -151 -337 -317 -4 -13 -16 -18 -39 -18 -42
0 -136 -32 -192 -65 -58 -33 -138 -124 -169 -189 -123 -260 32 -575 312 -635
29 -6 66 -11 81 -11 l29 0 3 -79 c4 -88 19 -118 79 -158 33 -23 37 -23 362
-23 365 0 376 2 421 64 17 24 23 48 25 111 l4 81 76 11 c271 37 445 295 377
558 -22 84 -60 147 -126 210 -81 77 -164 115 -274 123 l-86 7 -24 58 c-83 205
-306 322 -522 272z m409 -533 l88 -88 0 -369 c0 -365 0 -369 -22 -392 l-21
-23 -323 0 -323 0 -20 26 c-20 26 -21 37 -21 448 0 455 0 454 54 476 11 5 129
9 260 9 l240 1 88 -88z"/>
          <path fill="#0373ac" d="M660 580 l0 -360 255 0 255 0 0 285 0 285 -59 0 c-74 0 -91 18 -91
97 l0 53 -180 0 -180 0 0 -360z m284 133 c10 -82 56 -172 117 -227 28 -25 55
-46 60 -46 5 0 9 -3 9 -8 0 -4 -21 -12 -46 -18 l-45 -11 -35 41 c-20 23 -47
66 -62 97 l-26 55 -32 -65 c-18 -36 -46 -81 -62 -100 -25 -29 -31 -32 -50 -22
-11 6 -32 11 -46 11 -36 0 -32 11 15 40 81 49 157 192 146 273 -5 35 -4 37 23
37 26 0 28 -3 34 -57z"/>
        </g>
      </svg>
    </div>
  );
};
