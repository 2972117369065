import React from "react";
import { RouteInstance } from "src/components/Route/Route";
import {
  ASSESSOR,
  ASSESSOR_CANDIDATES,
  ASSESSOR_ROOMS,
  ASSESSOR_SPECIFIC_ROOM,
  CANDIDATE,
  CANDIDATE_LANDING,
  CANDIDATE_VIEWER,
  LOGIN,
  MIDDLEWARE,
} from "src/constants/route";
import LandingPage from "src/pages/Candidate/LandingPage/LandingPage";
import ViewerPage from "src/pages/Candidate/ViewerPage/ViewerPage";
import {
  Switch,
  Redirect,
  Route,
  useHistory,
  useParams,
} from "react-router-dom";
import { PageBase } from "src/components/PageBase/PageBase";
import { SpecificRoom } from "src/pages/Assessor/SpecificRoom/SpecificRoom";
import { LoginPage } from "src/pages/Authentication/LoginPage/LoginPage";
import { Middleware } from "src/pages/Auth/Middleware/Middleware";
import { CandidateList } from "src/pages/Assessor/CandidateList/CandidateList";
import { RoomList } from "src/pages/Assessor/RoomList/RoomList";
import { RouteForAssessor } from "src/pages/Auth/RouteForAssessor/RouteForAssessor";
import generatePath from "src/utils/regex";
import { RouteForCandidate } from "src/pages/Auth/RouteForCandidate/RouteForCandidate";

export const App = () => {
  const history = useHistory();
  const params = useParams();

  return (
    <Switch>
      <Redirect exact path="/" to={LOGIN} />
      <RouteInstance path={LOGIN} exact={true} component={LoginPage} />
      <RouteInstance path={MIDDLEWARE} exact={true} component={Middleware} />
      <Route
        path={ASSESSOR}
        render={() => (
          <Switch>
            <PageBase isAssessor={true}>
              <RouteForAssessor>
                <RouteInstance
                  path={ASSESSOR_ROOMS}
                  component={RoomList}
                  exact={true}
                />
                <RouteInstance
                  path={ASSESSOR_SPECIFIC_ROOM}
                  component={SpecificRoom}
                  exact={true}
                />
                <RouteInstance
                  path={ASSESSOR_CANDIDATES}
                  component={CandidateList}
                  exact={true}
                />
              </RouteForAssessor>
            </PageBase>
          </Switch>
        )}
      />
      <Route
        path={CANDIDATE}
        render={() => (
          <Switch>
            <PageBase isAssessor={false}>
              <RouteForCandidate>
                <RouteInstance path={CANDIDATE_LANDING} component={LandingPage} />
                <RouteInstance path={CANDIDATE_VIEWER} component={ViewerPage} />
              </RouteForCandidate>
            </PageBase>
          </Switch>
        )}
      />
    </Switch>
  );
};
