import {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import qs from 'qs';


/**
 * Hooks for usage of async/await in useEffect
 * @param effect The functional way
 * @param deps Dependency for the useEffect
 */
export const useEffectAsync = (effect, deps) => {
  useEffect(() => {
    effect();
  }, deps);
};


export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);
  return isMounted;
};

export const useQueryString = () => {
  const location = useLocation();
  return qs.parse(location.search.replace('?', ''));
};
