import { Route as RrdRoute, RouteProps } from "react-router-dom";
import * as React from "react";

const withRouterStoreReturnInstance = (WrappedComponent) => {
  return <WrappedComponent />;
};

export const RouteInstance: React.FC<RouteProps> = (props) => {
  const { component, ...otherProps } = props;

  return (
    <RrdRoute {...otherProps}>
      {withRouterStoreReturnInstance(component)}
    </RrdRoute>
  );
};
