import {
  ExtendedFirestoreInstance,
} from "react-redux-firebase";

export class BaseRepo {
  firestore: ExtendedFirestoreInstance;
  projectSlug: string;

  constructor(
    firestore: ExtendedFirestoreInstance,
    projectSlug: string,
  ) {
    this.firestore = firestore;
    this.projectSlug = projectSlug;
  }
}
