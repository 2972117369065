import * as React from "react";
import * as style from "./loginPage.module.scss";
import "antd/dist/antd.css";
import { Button, Card, Input, Form, Checkbox, message, Result } from "antd";
import { Logo } from "src/components/Logo/Logo";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { UserRepo } from "src/models/repositories/user";
import { useState } from "react";

interface LoginPageProps {}

export const LoginPage: React.FC<LoginPageProps> = (props) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const userRepo = new UserRepo(firebase, firestore);

  const [successEmail, setSuccessEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  const onSuccess = async (values) => {
    setLoading(true);
    const user: any = await userRepo.getUser(values.email);
    const projectSlug = user?.assessorProjectIds?.[0];
    if (projectSlug) {
      try {
        message.loading({
          content: "Sending magic link to your email..",
          key: "login",
        });
        await userRepo.sendLoginLinkToAssessor(values.email, user.name, projectSlug);
        message.success({
          content: "Successfully sent magic link to your email.",
          key: "login",
        });
        setSuccessEmail(values.email);
      } catch (e) {
        console.error("Error sending link: ", e);
        message.error({
          content: "Error sending magic link to your email. Please try again.",
          key: "login",
        });
      }
    } else {
      message.error({
        content: "Not a valid user. Please contact your admin.",
        key: "login",
      });
    }
    setLoading(false);
  };

  return (
    <div className={`${style.loginPage}`}>
      <div
        className={`d-flex flex-column w-100 align-items-center justify-content-center ${style.container}`}
      >
        <Logo className={style.logo} />
        <Card className={style.card} title="Login">
          {successEmail ? (
            <Result
              status="success"
              title="Check your inbox"
              subTitle={`We just emailed a magic link to ${successEmail}. Click the link to sign in.`}
              extra={[
                <Button
                  type="primary"
                  onClick={() => setSuccessEmail(undefined)}
                >
                  Back
                </Button>,
              ]}
            />
          ) : (
            <Form onFinish={onSuccess}>
              <Form.Item
                name="email"
                label={`Email`}
                rules={[
                  {
                    type: "email",
                    message: "Please input a valid email.",
                  },
                  {
                    required: true,
                    message: "Please input your email.",
                  },
                ]}
              >
                <Input placeholder={"name@example.com"} />
              </Form.Item>
              <Form.Item className={`mt-4`}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Login
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </div>
    </div>
  );
};
