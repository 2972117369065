import * as React from "react";
import * as style from "./table.module.scss";
import { Button, Dropdown, Input, Menu, Table as AntTable } from "antd";
import { useRef, useState } from "react";
import { ColumnType, TableProps as AntTableProps } from "antd/es/table";
import { EllipsisOutlined } from "@ant-design/icons";
import { MenuItemProps } from "antd/es/menu/MenuItem";
import * as _ from "lodash";
import { useEffectAsync } from "src/utils/hooks";
import { SearchOutlined } from "@ant-design/icons/lib";
import Highlighter from "react-highlight-words";

// @ts-ignore
export interface ActionBtnDropDownItems extends MenuItemProps {
  title: string;
  onClick: (text, record, index) => void;
}

export interface TableColumn extends ColumnType<any> {
  showSearch?: boolean;
}

interface TableProps extends AntTableProps<any> {
  selectedRows?: any[];
  selectedRowsOnChange?: (selectedRows) => void;
  enableSelection?: boolean;
  actionBtnDropDown?: (record?) => ActionBtnDropDownItems[];
}

export const Table: React.FC<TableProps> = (props) => {
  const {
    enableSelection = true,
    actionBtnDropDown,
    selectedRows,
    ...otherProps
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState();

  const [searchText, setSearchText] = useState<string>();
  const [searchedColumn, setSearchedColumn] = useState<string>();
  const searchInputRef = useRef<Input>();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    const selectedItems = props.dataSource
      .map(
        (item) =>
          !_.isUndefined(_.get(item, "key")) &&
          selectedRowKeys.includes(item.key) &&
          item
      )
      .filter((i) => i);

    props.selectedRowsOnChange &&
      props.selectedRowsOnChange(
        _.isArray(selectedItems) ? selectedItems : [selectedItems]
      );
  };

  useEffectAsync(() => {
    if (_.isEmpty(selectedRows)) {
      setSelectedRowKeys([]);
    }
  }, [selectedRows]);

  const getColumns = () => {
    if (actionBtnDropDown) {
      return [
        ...props.columns.map((item: any) => {
          return _.extend(
            {
              ...item,
            },
            item.showSearch && { ...getColumnSearchProps(item.dataIndex) }
          );
        }),
        {
          title: (
            <div className={`d-flex w-100 justify-content-center`}>Action</div>
          ),
          key: "operation",
          fixed: "right",
          width: 100,
          render: (text, record, index) => {
            return (
              <div
                className={`d-flex w-100 justify-content-center ${style.ellipsisIcon}`}
              >
                <Dropdown
                  overlay={
                    <Menu>
                      {props.actionBtnDropDown &&
                        props.actionBtnDropDown(record).map((item) => (
                          <Menu.Item
                            {...item}
                            onClick={() => item.onClick(text, record, index)}
                          >
                            {item.title}
                          </Menu.Item>
                        ))}
                    </Menu>
                  }
                >
                  <EllipsisOutlined />
                </Dropdown>
              </div>
            );
          },
        },
      ] as ColumnType<any>;
    }

    return props.columns as ColumnType<any>;
  };

  return (
    <div className={`${style.table}`}>
      <AntTable
        rowSelection={
          enableSelection && {
            selectedRowKeys,
            onChange: onSelectChange,
            fixed: true,
          }
        }
        {...otherProps}
        columns={getColumns() as any}
      />
    </div>
  );
};
