import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PdfViewer } from "src/components/PdfViwer/PdfViewer";
import { message, Spin } from "antd";
import {
  FirebaseReducer,
  isLoaded,
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { CandidateStatuses, collections } from "src/constants/firestore";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import { IRoom, IRoomCandidate, IUser } from "src/models/types";
import { useEffectAsync, useQueryString } from "src/utils/hooks";
import useLocalStorage from "react-use-localstorage";
import { isDateMoreThanNow } from "src/utils/countdown";
import Countdown from "ant-design-pro/lib/CountDown";
import { usePageVisibility } from "src/utils/pageVisibility";
import * as _ from "lodash";
import { RoomRepo } from "src/models/repositories/room";
import { MaterialRepo } from "src/models/repositories/material";

interface ViewerPageProps {}

const ViewerPage: React.FC<ViewerPageProps> = (props) => {
  const queryString: any = useQueryString();
  const [secondStorage, setSecondStorage] = useLocalStorage("seconds");
  const [documentLink, setDocumentLink] = useState();
  const [existingMaterialId, setExistingMaterialId] = useState();
  const [unloading, setUnloading] = useState<boolean>(false);
  const { project_slug, room_id } = useParams();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const roomRepo = new RoomRepo(firestore, project_slug);
  const materialRepo = new MaterialRepo(firebase, firestore);

  const isVisible = usePageVisibility();

  useFirestoreConnect(() => [
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [
        {
          collection: collections.rooms,
          doc: room_id,
          subcollections: [
            {
              collection: collections.candidates,
              doc: queryString.cid,
            },
          ],
        },
      ],
      storeAs: "candidate",
    },
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [
        {
          collection: collections.rooms,
          doc: room_id,
        },
      ],
      storeAs: "room",
    },
  ]);

  const candidate = useSelector<StoreInterface, IRoomCandidate>(
    (state) => state.firestore.ordered.candidate?.[0]
  );
  const room = useSelector<StoreInterface, IRoom[]>(
    (state) => state.firestore.ordered.room
  );


  const profile: any = useSelector<
    StoreInterface,
    FirebaseReducer.Profile<{}> | IUser
  >((state) => state.firebase.profile);

  console.log("candidate: ", candidate);

  useEffectAsync(async () => {
    if (isLoaded(room) && existingMaterialId !== room[0].materialIds[0]) {
      setExistingMaterialId(room[0].materialIds[0]);
      try {
        const pdfLink = await materialRepo.getMaterialBinary(profile?.token?.token, room[0].materialIds[0], true);
        setDocumentLink(pdfLink);
      } catch (e) {
        console.error(e);
        setDocumentLink("error");
        message.error(`Failed to load document`);
      }
    }
  }, [room]);

  useEffectAsync(() => {
    if (candidate && candidate.endAt) {
      setSecondStorage(candidate.endAt.seconds.toString());
    } else {
      setSecondStorage(undefined);
    }
  }, [candidate]);

  useEffectAsync(async () => {
    console.log("isVisible: ", isVisible);
    const isDateValid = isDateMoreThanNow(Number(secondStorage));
    let status: CandidateStatuses = CandidateStatuses.OFFLINE;
    if (isVisible && isDateValid) {
      status = CandidateStatuses.VIEWING;
    } else if (isVisible) {
      status = CandidateStatuses.ONLINE;
    } else {
      status = CandidateStatuses.OFFLINE;
    }

    if (candidate && status !== candidate.status && !unloading) {
      await roomRepo.updateStatus(room_id, candidate.id, status);
    }
  }, [candidate, isVisible, secondStorage]);

  const handleOnCloseTab = async () => {
    setUnloading(true);
    if (candidate) {
      await roomRepo.updateStatus(
        room_id,
        candidate.id,
        CandidateStatuses.OFFLINE
      );
    }
  };

  useEffect(() => {
    window.addEventListener("unload", handleOnCloseTab, true);
    return () => {
      window.removeEventListener("unload", handleOnCloseTab, true);
    };
  }, [handleOnCloseTab]);

  return (
    <div
      className={`w-100 h-100 d-flex justify-content-center align-items-center`}
    >
      {!isLoaded(candidate) ||
      !isDateMoreThanNow(Number(secondStorage)) ||
      _.isEmpty(documentLink) ? (
        <Spin
          tip="Please wait for the host to start the document..."
          size={"large"}
        />
      ) : (
        <>
          <PdfViewer file={documentLink} />
          <Countdown
            target={isDateMoreThanNow(Number(secondStorage))}
            onEnd={() => setSecondStorage(undefined)}
            style={{
              display: "none",
            }}
          />
        </>
      )}
    </div>
  );
};

export default ViewerPage;
