import * as React from "react";
import * as style from "./roomList.module.scss";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import { Button, message, Modal } from "antd";
import {
  isLoaded,
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import * as _ from "lodash";
import {ActionBtnDropDownItems, Table, TableColumn} from "src/components/Table/Table";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { ColumnsType } from "antd/es/table";
import { CandidateStatus } from "src/components/CandidateStatus/CandidateStatus";
import { isDateMoreThanNow } from "src/utils/countdown";
import Countdown from "ant-design-pro/lib/CountDown";
import { RoomRepo } from "src/models/repositories/room";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import {
  ICandidate,
  ICandidateList,
  IRoom,
  IRoomCandidate,
} from "src/models/types";
import { useState } from "react";
import { CandidateRepo } from "src/models/repositories/candidate";
import { collections } from "src/constants/firestore";
import { CreateRoomModal } from "../components/CreateRoomModal/CreateRoomModal";
import { useEffectAsync } from "src/utils/hooks";
import { sorterByString } from "src/utils/table";

interface RoomListProps {}

export const RoomList: React.FC<RoomListProps> = (props) => {
  const { project_slug } = useParams();
  const history = useHistory();
  const firestore = useFirestore();

  const roomRepo = new RoomRepo(firestore, project_slug);

  const [createRoomModalIsOpen, setCreateRoomModalIsOpen] = useState<boolean>(
    false
  );

  const [editRoom, setEditRoom] = useState<IRoom>();

  useFirestoreConnect(() => [
    { collection: collections.projects },
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [{ collection: collections.rooms }],
      storeAs: "masterRooms",
    },
    {
      collection: collections.materials,
      storeAs: "masterDocuments",
    },
  ]);

  const masterRooms = useSelector<StoreInterface, ICandidateList[]>(
    (state) => state.firestore.ordered.masterRooms
  );

  const masterDocuments = useSelector<StoreInterface, ICandidateList[]>(
    (state) => state.firestore.ordered.masterDocuments
  );

  useEffectAsync(() => {
    if (!createRoomModalIsOpen) {
      setEditRoom(undefined);
    }
  }, [createRoomModalIsOpen]);

  const columns: TableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => sorterByString(a, b, "name"),
      showSearch: true
    },
    {
      title: "Total Candidates",
      dataIndex: "candidateIds",
      sorter: (a: any, b: any) => {
        return Number(a.candidateIds?.length || 0) - Number(b.candidateIds?.length || 0);
      },
      render: (text, record: any, index) => {
        return record.candidateIds?.length || 0;
      },
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
      sorter: (a, b) => sorterByString(a, b, "document_name"),
    },
  ];

  const mapTableDataToDocument = roomRepo.mapRoomsToDocument(
    masterRooms,
    masterDocuments
  );

  const actionMenuIcon = (
    record?: IRoomCandidate & ICandidate,
    isMulti: boolean = false
  ): ActionBtnDropDownItems[] => {
    return [
      !isMulti && {
        title: "View Room",
        onClick: async (room: IRoom) => {
          if (room !== undefined) {
            await history.push(`${history.location.pathname}/${room?.id}`);
          }
        },
      },
      !isMulti && {
        title: "Edit Room",
        onClick: async (room: IRoom) => {
          setEditRoom(room);
          setCreateRoomModalIsOpen(true);
        },
      },
      {
        title: "Remove",
        onClick: (room: IRoom) => {
          Modal.confirm({
            title: `Are you sure want to remove ${record.name} from the room list?`,
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async () => {
              message.loading({
                content: "Removing room...",
                key: "remove_room",
              });
              await roomRepo.deleteRoom(room.id);
              message.success({
                content: "Successfully removed room.",
                key: "remove_room",
              });
            },
          });
        },
      },
    ].filter((i) => i);
  };

  return (
    <div className={`${style.roomList}`}>
      <PageHeader
        title={"Rooms List"}
        className="site-page-header"
        extra={[
          <Button
            key="1"
            type={"primary"}
            onClick={() => setCreateRoomModalIsOpen(true)}
          >
            {`Create New Room`}
          </Button>,
        ]}
      >
        <Table
          scroll={{ x: "100%", y: 500 }}
          columns={columns}
          dataSource={mapTableDataToDocument}
          actionBtnDropDown={actionMenuIcon}
          loading={!isLoaded(masterRooms) || !isLoaded(masterDocuments)}
          enableSelection={false}
          pagination={{
            total: mapTableDataToDocument.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} rooms`,
          }}
        />
      </PageHeader>
      <CreateRoomModal
        toggle={() => setCreateRoomModalIsOpen(!createRoomModalIsOpen)}
        visible={createRoomModalIsOpen}
        editRoom={editRoom}
      />
    </div>
  );
};
