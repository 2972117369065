import { collections } from "src/constants/firestore";
import {
  ICandidate,
  ICandidateList,
  IRoom,
} from "src/models/types";
import * as _ from 'lodash';
import { BaseRepo } from "src/models/baseRepo";

export class CandidateRepo extends BaseRepo {
  mapCandidatesToRoom(candidates: ICandidateList[], rooms: IRoom[]) {
    if (candidates) {
      candidates = candidates.map((candidate, key) => {
        const roomNames = candidate.roomIds?.map(
          (roomId) => _.find(rooms, { id: roomId })?.name
        );

        return {
          ...candidate,
          roomNames: roomNames,
          key: key,
        };
      });
    }
    return candidates;
  }

  async createCandidate(candidate: ICandidate, checkExist = true) {
    const { email, ...candidateData } = candidate;
    const _email = email.toLowerCase();
    const candidatesPath = `${collections.projects}/${this.projectSlug}/${collections.candidates}`;
    if (checkExist) {
      const candidateSnap = await this.firestore.collection(candidatesPath)
        .where('email', '==', _email)
        .get();
      if (!candidateSnap.empty) {
        throw new Error(`Candidate email already exists.`);
      }
    }
    await this.firestore.add(candidatesPath, {email: _email, ...candidateData});
  }

  async updateCandidate(candidateId: string, candidate: ICandidate) {
    await this.firestore.update(
      `${collections.projects}/${this.projectSlug}/${collections.candidates}/${candidateId}`,
      {
        name: candidate.name,
        email: candidate.email,
      }
    );
  }

  async deleteCandidate(candidate: ICandidate) {
    for (const roomId of candidate.roomIds) {
      await this.firestore.delete(
        `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}/${collections.candidates}/${candidate.id}`
      );
    }
    await this.firestore.delete(
      `${collections.projects}/${this.projectSlug}/${collections.candidates}/${candidate.id}`
    );
  }
}
