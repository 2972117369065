import * as React from "react";
import { parse } from "papaparse";
import { CandidateRepo } from "src/models/repositories/candidate";
import { ExtendedFirestoreInstance } from "react-redux-firebase";
import { message } from "antd";
import { IAssessor, ICandidate } from "src/models/types";
import { useEffect, useState } from "react";
import { AssessorRepo } from "src/models/repositories/assessor";

interface ImportResourceInputProps {
  fileRef: React.MutableRefObject<HTMLInputElement>;
  firestore: ExtendedFirestoreInstance;
  projectSlug: string;
  resource: 'assessors' | 'candidates';
  existingResources: IAssessor[] | ICandidate[];
}

export const ImportResourceInput: React.FC<ImportResourceInputProps> = (props) => {
  const { fileRef, firestore, projectSlug, resource, existingResources } = props;
  const [resources, setResources] = useState(existingResources);

  useEffect(() => {
    setResources(existingResources);
  }, [existingResources]);

  return (
    <input
      accept="text/csv"
      type="file"
      id="file"
      ref={fileRef}
      style={{ display: 'none' }}
      onClick={(ev) => ev.currentTarget.value = null}
      onChange={() => {
        const { files } = fileRef.current;
        if (files.length) {
          message.loading({
            content: `Adding ${resource}...`,
            key: `import_${resource}`,
          });
          const file = files[0];
          parse(file, {
            header: true,
            complete: async (result) => {
              switch (resource) {
                case "candidates":
                  const candidateRepo = new CandidateRepo(firestore, projectSlug);
                  for (const candidate of result.data) {
                    if (candidate.email && candidate.name &&
                      (!resources.some(x => x.email === candidate.email))) {
                      await candidateRepo.createCandidate(candidate, false);
                    }
                  }
                  break;
                case "assessors":
                  const assessorRepo = new AssessorRepo(firestore, projectSlug);
                  for (const assessor of result.data) {
                    if (assessor.email && assessor.name &&
                      (!resources.some(x => x.email === assessor.email))) {
                      await assessorRepo.createAssessor(assessor, false);
                    }
                  }
                  break;
                default:
                  break;
              }
              message.success({
                content: `Successfully added ${resource}.`,
                key: `import_${resource}`
              });
            }
          });
        }
      }}
    />
  );
};
