import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {AppBridge} from "src/AppBridge";

const root = document.createElement('div');
document.body.appendChild(root);
document.body.classList.add('defaultLight');
document.title = `Accendo Assessment Centre Cloud Doc`;

ReactDOM.render(
  <React.StrictMode>
    <AppBridge />
  </React.StrictMode>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
