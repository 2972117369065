import * as React from "react";
import * as style from "./candidateList.module.scss";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Tag,
} from "antd";
import {
  isLoaded, useFirebase,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import * as _ from "lodash";
import {ActionBtnDropDownItems, Table, TableColumn} from "src/components/Table/Table";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { ColumnsType } from "antd/es/table";
import { RoomRepo } from "src/models/repositories/room";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import {
  ICandidate,
  ICandidateList,
  IRoom,
  IRoomCandidate,
} from "src/models/types";
import { useRef, useState } from "react";
import { CandidateRepo } from "src/models/repositories/candidate";
import { collections } from "src/constants/firestore";
import { MultiAddToRoom } from "src/pages/Assessor/components/MultiAddToRoom/MultiAddToRoom";
import { CreateCandidateModal } from "src/pages/Assessor/components/CreateCandidateModal/CreateCandidateModal";
import { useEffectAsync } from "src/utils/hooks";
import { CreateRoomModal } from "src/pages/Assessor/components/CreateRoomModal/CreateRoomModal";
import {sorterByString} from "src/utils/table";
import { ImportResourceInput } from "src/pages/Assessor/components/ImportResourceInput/ImportResourceInput";

interface CandidateListProps {}

export const CandidateList: React.FC<CandidateListProps> = (props) => {
  const [selectedRows, setSelectedRows] = useState<
    (ICandidate & IRoomCandidate)[]
  >();
  const [multiAddVisible, setMultiAddVisible] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const { project_slug } = useParams();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const candidateRepo = new CandidateRepo(firestore, project_slug);
  const roomRepo = new RoomRepo(firestore, project_slug);

  useFirestoreConnect(() => [
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [{ collection: collections.candidates }],
      storeAs: "masterCandidates",
    },
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [{ collection: collections.rooms }],
      storeAs: "masterRooms",
    },
  ]);

  const masterCandidates = useSelector<StoreInterface, ICandidateList[]>(
    (state) => state.firestore.ordered.masterCandidates
  );

  const masterRooms = useSelector<StoreInterface, IRoom[]>(
    (state) => state.firestore.ordered.masterRooms
  );

  const [createCandidateModalIsOpen, setCreateCandidateModalIsOpen] = useState<
    boolean
  >(false);

  const [editCandidate, setEditCandidate] = useState<ICandidate>();

  const [createRoomModalIsOpen, setCreateRoomModalIsOpen] = useState<boolean>(
    false
  );

  useEffectAsync(() => {
    if (!createCandidateModalIsOpen) {
      setEditCandidate(undefined);
    }
  }, [createCandidateModalIsOpen]);

  const columns: TableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a,b) => sorterByString(a,b,'name'),
      showSearch: true
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a,b) => sorterByString(a,b,'email'),
      showSearch: true
    },
    {
      title: "Room",
      dataIndex: "roomNames",
      render: (roomNames) => {
        return roomNames ? (
          <div className={`d-flex flex-wrap ${style.tags}`}>
            {roomNames?.map((item) => {
              return item && <Tag color={"geekblue"}>{item}</Tag>;
            })}
          </div>
        ) : (
          "-"
        );
      },
    },
  ];

  const tableData = candidateRepo.mapCandidatesToRoom(
    masterCandidates,
    masterRooms
  );

  const actionMenuIcon = (
    record?: IRoomCandidate & ICandidate,
    isMulti: boolean = false
  ): ActionBtnDropDownItems[] => {
    return [
      !isMulti && {
        title: "Edit Candidate",
        onClick: async (candidate: ICandidate) => {
          setEditCandidate(candidate);
          setCreateCandidateModalIsOpen(true);
        },
      },
      !isMulti && {
        title: "Remove",
        onClick: (candidate: ICandidate) => {
          Modal.confirm({
            title: `Are you sure want to remove ${record.name}?`,
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async () => {
              message.loading({
                content: "Deleting candidate...",
                key: "delete_candidate",
              });
              await candidateRepo.deleteCandidate(candidate);
              message.success({
                content: "Successfully deleted candidate.",
                key: "delete_candidate",
              });
            },
          });
        },
      },
      isMulti && {
        title: "Create New Room",
        onClick: async () => {
          await setCreateRoomModalIsOpen(true);
        },
      },
      isMulti && {
        title: "Add To Existing Room",
        onClick: async () => {
          await setMultiAddVisible(true);
        },
      },
      isMulti && {
        title: "Remove",
        onClick: async () => {
          Modal.confirm({
            title: `Are you sure want to remove the following candidate(s)?`,
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            content: (
              <div className={style.tags}>
                {selectedRows?.map((item) => {
                  return <Tag color={"geekblue"}>{item.name}</Tag>;
                })}
              </div>
            ),
            onOk: () => {
              message.loading({
                content: "Deleting candidate(s)...",
                key: "delete_candidate",
              });
              selectedRows?.map(async (item) => {
                await candidateRepo.deleteCandidate(item);
              });
              setSelectedRows([]);
              message.success({
                content: "Successfully deleted candidate(s).",
                key: "delete_candidate",
              });
            },
          });
        },
      },
    ].filter((i) => i);
  };

  const createActionMenuIcon = (): ActionBtnDropDownItems[] => {
    return [
      {
        title: "Upload Candidates",
        onClick: () => fileRef.current.click(),
      },
      {
        title: "Download Candidates Template",
        onClick: () => {
          firebase.storage()
            .ref()
            .child('template.csv')
            .getDownloadURL()
            .then(url => {
              window.location.href = url;
            })
            .catch(e => {
              message.error('Failed to download template.');
            });
        },
      },
    ].filter((i) => i);
  };

  return (
    <div className={`${style.candidateList}`}>
      <PageHeader
        title={"Candidate List"}
        className={`site-page-header ${style.createCandidateBtn}`}
        extra={[
          <Dropdown
            overlay={
              <Menu>
                {actionMenuIcon(null, true).map((item: any) => (
                  <Menu.Item {...item}>{item.title}</Menu.Item>
                ))}
              </Menu>
            }
            disabled={_.isEmpty(selectedRows)}
          >
            <Button>
              {`Action ${
                selectedRows?.length ? `(${selectedRows?.length})` : ""
              }`}
              <DownOutlined />
            </Button>
          </Dropdown>,
          <Dropdown.Button
            className={style.createCandidateBtn}
            type={"primary"}
            onClick={() => {
              setCreateCandidateModalIsOpen(true);
            }}
            overlay={
              <Menu>
                {createActionMenuIcon().map((item: any) => (
                  <Menu.Item {...item}>{item.title}</Menu.Item>
                ))}
              </Menu>
            }
          >
            {`Create New Candidate`}
          </Dropdown.Button>,
          <ImportResourceInput
            fileRef={fileRef}
            firestore={firestore}
            projectSlug={project_slug}
            resource={'candidates'}
            existingResources={masterCandidates}
          />
        ]}
      >
        <Table
          scroll={{ x: "100%", y: 500 }}
          columns={columns}
          dataSource={tableData}
          actionBtnDropDown={actionMenuIcon}
          loading={!isLoaded(masterCandidates)}
          selectedRowsOnChange={(selected) => setSelectedRows(selected)}
          selectedRows={selectedRows}
          pagination={{
            total: tableData?.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} candidates`,
          }}
        />
        <MultiAddToRoom
          roomRepo={roomRepo}
          candidates={selectedRows}
          visible={multiAddVisible}
          setVisible={setMultiAddVisible}
        />
      </PageHeader>
      <CreateCandidateModal
        toggle={() =>
          setCreateCandidateModalIsOpen(!createCandidateModalIsOpen)
        }
        visible={createCandidateModalIsOpen}
        editCandidate={editCandidate}
      />
      <CreateRoomModal
        toggle={() => setCreateRoomModalIsOpen(!createRoomModalIsOpen)}
        visible={createRoomModalIsOpen}
        candidateIds={selectedRows?.map((item) => item.id)}
      />
    </div>
  );
};
