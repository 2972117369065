import * as React from "react";
import * as style from "./topMenu.module.scss";
import { Menu } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import { InboxOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import generatePath from "src/utils/regex";
import {
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ASSESSOR_CANDIDATES, ASSESSOR_ROOMS } from "src/constants/route";
import useReactRouter from "use-react-router";
import { useEffectAsync } from "src/utils/hooks";
import { matchPath } from "react-router";

interface TopMenuProps {}

interface MenuData {
  key: string;
  title: string;
  icon: React.ReactNode | Element;
}

export const TopMenu: React.FC<TopMenuProps> = (props) => {
  const [currentSelected, setCurrentSelected] = useState<string>();

  const { history, location, match } = useReactRouter();
  const params = useParams();

  const menuData: MenuData[] = [
    {
      key: ASSESSOR_ROOMS,
      title: `Rooms`,
      icon: <InboxOutlined />,
    },
    {
      key: ASSESSOR_CANDIDATES,
      title: `Candidates`,
      icon: <UsergroupAddOutlined />,
    },
  ];

  useEffectAsync(() => {
    const getCurrentPath = menuData
      .map((item) => {
        const matchPaths = matchPath(location.pathname, {
          path: item.key,
          exact: true,
          strict: true,
        });
        return matchPaths && matchPaths.path;
      })
      .filter((i) => i);

    setCurrentSelected(getCurrentPath[0]);
  }, []);

  const handleClick = (e) => {
    setCurrentSelected(e.key);
    history.push(generatePath(e.key, { ...params }));
  };

  return (
    <div className={`${style.topMenu}`}>
      <Menu
        onClick={handleClick}
        selectedKeys={[currentSelected]}
        mode="horizontal"
      >
        {menuData.map((item) => (
          <Menu.Item key={item.key}>
            {item.icon}
            {item.title}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};
