import {
  ExtendedAuthInstance,
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
  ExtendedStorageInstance,
} from "react-redux-firebase";
import { collections } from "src/constants/firestore";
import { IMaterial } from "src/models/types";
import axios from "axios";

export class MaterialRepo {
  firebase: ExtendedFirebaseInstance &
    ExtendedAuthInstance &
    ExtendedStorageInstance;
  firestore: ExtendedFirestoreInstance;

  constructor(firebase, firestore: ExtendedFirestoreInstance) {
    this.firebase = firebase;
    this.firestore = firestore;
  }

  async createMaterial(material: IMaterial) {
    // todo: upload file to storage
    await this.firestore.add(collections.materials, material);
  }

  async updateMaterial(materialId: string, material: IMaterial) {
    const { id, ...materialData } = material;
    await this.firestore.update(
      `${collections.materials}/${materialId}`,
      materialData
    );
  }

  async deleteMaterial(materialId: string) {
    await this.firestore.delete(`${collections.materials}/${materialId}`);
  }

  async getMaterial(materialId: string) {
    const materialSnap = await this.firestore
      .collection(collections.materials)
      .doc(materialId)
      .get();
    return materialSnap.data() as IMaterial;
  }

  async getDownloadUrl(materialId: string) {
    const material = await this.getMaterial(materialId);
    if (!material) {
      return null;
    }
    return await this.firebase
      .storage()
      .ref(material.storagePath)
      .getDownloadURL();
  }

  async getMaterialBinary(
    token: string,
    materialId: string,
    getAsLink: boolean = false
  ) {
    const result = await axios.post(
      `https://us-central1-accendo-doc.cloudfunctions.net/getMaterialBinaryData`,
      {materialId},
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type':  'application/json',
        },
      }
    );

    if (getAsLink) {
      const blob: any = new Blob([result.data], {
        type: result.headers[`content-type`],
      });

      return URL.createObjectURL(blob);
    }

    return result.data;
  }
}
