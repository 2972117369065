import * as React from "react";
import * as style from "./createCandidateModal.modules.scss";
import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import { ICandidate } from "src/models/types";
import { useState } from "react";
import { ModalProps } from "antd/es/modal";
import * as _ from "lodash";
import { Simulate } from "react-dom/test-utils";
import { useParams } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { CandidateRepo } from "src/models/repositories/candidate";
import { useEffectAsync } from "src/utils/hooks";

interface CreateCandidateModalProps extends ModalProps {
  toggle: () => void;
  editCandidate?: ICandidate;
}

export const CreateCandidateModal: React.FC<CreateCandidateModalProps> = (
  props
) => {
  const { editCandidate, toggle, ...otherProps } = props;
  const [form] = Form.useForm();
  const [loading, setIsLoading] = useState<boolean>(false);

  const { project_slug } = useParams();
  const firestore = useFirestore();
  const candidateRepo = new CandidateRepo(firestore, project_slug);

  useEffectAsync(() => {
    if (props.visible) {
      form.resetFields();
      if (editCandidate) {
        form.setFieldsValue({
          name: editCandidate.name,
          email: editCandidate.email
        });
      }
    }
  }, [props.visible]);

  const onClickOk = async () => {
    try {
      const values: any = await form.validateFields();
      setIsLoading(true);
      message.loading({
        content: `${editCandidate ? `Updating` : `Creating`} candidate...`,
        key: "create_candidate",
      });

      const data: ICandidate = {
        name: values.name,
        email: values.email,
      };

      if (editCandidate) {
        await candidateRepo.updateCandidate(editCandidate.id, data);
      } else {
        await candidateRepo.createCandidate(data);
      }

      form.resetFields();
      toggle();
      message.success({
        content: `Successfully ${
          editCandidate ? `updated` : `created`
        } candidate`,
        key: "create_candidate",
      });
    } catch (e) {
      if (e?.errorFields) {
        console.error("Validate Failed:", e);
        return;
      }
      message.error({
        content: `Error ${editCandidate ? `updating` : `creating`} candidate. ${e.message}`,
        key: 'create_candidate'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        title={`${editCandidate ? `Edit` : `Create New`} Candidate`}
        okText={`${editCandidate ? `Update` : `Create`}`}
        cancelText="Cancel"
        onCancel={() => toggle()}
        onOk={onClickOk}
        okButtonProps={{
          loading: loading,
        }}
        {...otherProps}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter the candidate's name",
              },
            ]}
          >
            <Input placeholder={"Enter candidate'name here.."} />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email.",
              },
              {
                required: true,
                message: "Please enter the candidate's email.",
              },
            ]}
          >
            <Input placeholder={"Enter candidate's email here.."} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
