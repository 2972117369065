import * as React from "react";
import * as style from "./candidateStatus.module.scss";
import { Tag } from "antd";
import {CandidateStatuses} from "src/constants/firestore";

interface CandidateStatusProps {
  status: CandidateStatuses;
}

export const CandidateStatus: React.FC<CandidateStatusProps> = (props) => {
  const { status } = props;

  const getStatus = () => {
    switch (status) {
      case CandidateStatuses.ONLINE:
        return <Tag color={`green`}>{CandidateStatuses.ONLINE}</Tag>;
      case CandidateStatuses.OFFLINE:
        return <Tag color={`red`}>{CandidateStatuses.OFFLINE}</Tag>;
      case CandidateStatuses.VIEWING:
        return <Tag color={`blue`}>{CandidateStatuses.VIEWING}</Tag>;
      default:
        return <Tag color={`red`}>{CandidateStatuses.OFFLINE}</Tag>;
    }
  };

  return getStatus();
};
