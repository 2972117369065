import * as React from "react";
import { useState } from "react";
import * as style from "./specificRoom.module.scss";
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Popconfirm,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { PageHeader } from "src/components/PageHeader/PageHeader";
import {ActionBtnDropDownItems, Table, TableColumn} from "src/components/Table/Table";
import { CandidateStatus } from "src/components/CandidateStatus/CandidateStatus";
import { DownOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import {
  isLoaded,
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { CandidateStatuses, collections } from "src/constants/firestore";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import { ICandidate, IRoom, IRoomCandidate } from "src/models/types";
import { RoomRepo } from "src/models/repositories/room";
import * as _ from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import { getPeriodSeconds } from "src/pages/Assessor/utils/GetPeriodSeconds/getPeriodSeconds";
import Countdown from "ant-design-pro/lib/CountDown";
import { isDateMoreThanNow } from "src/utils/countdown";
import { ASSESSOR_ROOMS } from "src/constants/route";
import generatePath from "src/utils/regex";
import {sorterByString} from "src/utils/table";

interface SpecificRoomProps {}

export const SpecificRoom: React.FC<SpecificRoomProps> = (props) => {
  const { project_slug, room_id } = useParams();

  const firestore = useFirestore();
  const firebase = useFirebase();
  const history = useHistory();

  useFirestoreConnect(() => [
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [
        {
          collection: collections.rooms,
          doc: room_id,
        },
      ],
      storeAs: "room",
    },
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [{ collection: collections.candidates }],
      storeAs: "masterCandidates",
    },
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [{ collection: collections.candidates }],
      where: ["roomIds", "array-contains", room_id],
      storeAs: "filterMasterCandidates",
    },
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [
        {
          collection: collections.rooms,
          doc: room_id,
          subcollections: [{ collection: collections.candidates }],
        },
      ],
      storeAs: "roomCandidates",
    },
  ]);

  const roomRepo = new RoomRepo(firestore, project_slug);

  const masterCandidates = useSelector<StoreInterface, ICandidate[]>(
    (state) => state.firestore.ordered.masterCandidates
  );

  const filteredMasterCandidates = useSelector<StoreInterface, ICandidate[]>(
    (state) => state.firestore.ordered.filterMasterCandidates
  );

  const room = useSelector<StoreInterface, IRoom[]>(
    (state) => state.firestore.ordered.room
  );

  const roomCandidates = useSelector<StoreInterface, IRoomCandidate[]>(
    (state) => state.firestore.ordered.roomCandidates
  );

  const [selectedRows, setSelectedRows] = useState<
    (ICandidate & IRoomCandidate)[]
  >();

  const columns: TableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => sorterByString(a, b, "name"),
      showSearch: true
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => sorterByString(a, b, "email"),
      showSearch: true
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => sorterByString(a, b, "status"),
      render: (text, record, index) => {
        return <CandidateStatus status={text} />;
      },
    },
    {
      title: "Remaining Time",
      dataIndex: "endAt",
      render: (text, record: any, index) => {
        if (text) {
          if (isDateMoreThanNow(text.seconds)) {
            return (
              <Countdown
                target={isDateMoreThanNow(text.seconds)}
                onEnd={() => roomRepo.revokeAccesses(room_id, [record.id])}
              />
            );
          }
        }
      },
    },
  ];

  const actionMenuIcon = (
    record?: IRoomCandidate & ICandidate,
    isMulti: boolean = false
  ): ActionBtnDropDownItems[] => {
    console.log("record: ", record);
    return [
      {
        title: "Enable View Document",
        onClick: async () => {
          const seconds = await getPeriodSeconds();
          if (seconds) {
            const ids = isMulti
              ? selectedRows.map((item) => item.id)
              : [record.id];
            message.loading({
              content: `Enabling document viewing...`,
              key: "enable_document",
            });
            await roomRepo.setAccesses(room_id, ids, seconds);
            message.success({
              content: `Successfully enabled document viewing`,
              key: "enable_document",
            });
          }
        },
      },
      {
        title: "Stop View Document",
        onClick: async () => {
          const ids = isMulti
            ? selectedRows.map((item) => item.id)
            : [record.id];
          message.loading({
            content: `Stopping document viewing...`,
            key: "stop_document",
          });
          await roomRepo.revokeAccesses(room_id, ids);
          message.success({
            content: `Successfully stopped document viewing`,
            key: "stop_document",
          });
        },
        disabled: !isMulti && !record?.endAt,
      },
      {
        title: "Send Invitation",
        onClick: async () => {
          try {
            message.loading({
              content: `Sending invitation...`,
              key: "send_invite",
            });
            const selectedCandidates = isMulti ? selectedRows : [record];
            await roomRepo.sendInvitations(
              firebase,
              room_id,
              selectedCandidates
            );

            message.success({
              content: `Sent invitation`,
              key: "send_invite",
            });
          } catch (e) {
            message.error({
              content: e?.message,
              key: "send_invite",
            });
          }
        },
      },
      !isMulti && {
        title: "Remove",
        onClick: () => {
          Modal.confirm({
            title: `Are you sure want to remove ${record.name} from this room?`,
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async () => {
              message.loading({
                content: `Removing ${record.name} from room...`,
                key: `remove_candidate`,
              });
              await roomRepo.removeCandidateFromRoom(room_id, record.id);
              message.success({
                content: `Removed ${record.name} from room`,
                key: `remove_candidate`,
              });
            },
          });
        },
      },
      isMulti && {
        title: "Remove",
        onClick: () => {
          Modal.confirm({
            title: `Are you sure want to remove the following candidate(s) from this room?`,
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            content: (
              <div className={style.tags}>
                {selectedRows?.map((item) => {
                  return <Tag color={"geekblue"}>{item.name}</Tag>;
                })}
              </div>
            ),
            onOk: async () => {
              message.loading({
                content: "Deleting candidate(s)...",
                key: "delete_candidate",
              });
              selectedRows?.map(async (item) => {
                await roomRepo.removeCandidateFromRoom(room_id, item.id);
              });
              setSelectedRows([]);
              message.success({
                content: "Successfully deleted candidate(s).",
                key: "delete_candidate",
              });
            },
          });
        },
      },
    ].filter((i) => i);
  };

  const onChangeAddCandidate = async (selectedItem) => {
    message.loading({
      content: `Adding candidate to room...`,
      key: selectedItem,
    });
    await roomRepo.addCandidateToRoom(room_id, selectedItem);
    message.success({
      content: `Successfully added candidate to room`,
      key: selectedItem,
    });
  };

  const tableData = roomRepo.mapCandidatesToRoom(
    roomCandidates,
    filteredMasterCandidates
  );

  const onClickEnableViewDocumentAll = async () => {
    const seconds = await getPeriodSeconds();
    if (seconds) {
      const ids = tableData.map((item) => item.id);
      message.loading({
        content: `Enabling document viewing...`,
        key: "enable_document",
      });
      await roomRepo.setAccesses(room_id, ids, seconds);
      message.success({
        content: `Successfully enabled document viewing`,
        key: "enable_document",
      });
    }
  };

  const onClickStopViewDocumentAll = async () => {
    const ids = tableData.map((item) => item.id);
    message.loading({
      content: `Stopping document viewing...`,
      key: "stop_document",
    });
    await roomRepo.revokeAccesses(room_id, ids);
    message.success({
      content: `Successfully stopped document viewing`,
      key: "stop_document",
    });
  };

  const isAnyCandidateEnabledViewDocument = () => {
    return _.some(
      tableData,
      (item: (ICandidate & IRoomCandidate) | any) =>
        item.endAt && isDateMoreThanNow(item.endAt.seconds)
    );
  };

  const getStatusCount = (status: CandidateStatuses) => {
    return (
      _.countBy(tableData, (record: ICandidate & IRoomCandidate) => {
        return record.status === status;
      }).true || 0
    );
  };

  return (
    <div className={`${style.specificRoom}`}>
      <PageHeader
        title={(!_.isEmpty(room) && room[0].name) || "..."}
        className="site-page-header"
        tags={[
          <Tag color="blue">{`${getStatusCount(CandidateStatuses.VIEWING)} ${
            CandidateStatuses.VIEWING
          }`}</Tag>,
          <Tag color="green">{`${getStatusCount(CandidateStatuses.ONLINE)} ${
            CandidateStatuses.ONLINE
          }`}</Tag>,
          <Tag color="red">{`${getStatusCount(CandidateStatuses.OFFLINE)} ${
            CandidateStatuses.OFFLINE
          }`}</Tag>,
        ]}
        extra={[
          <Select
            placeholder="Add candidate to room here.."
            onChange={onChangeAddCandidate}
            className={style.addCandidateSelect}
            showSearch={true}
            value={[]}
            autoClearSearchValue={true}
            loading={!isLoaded(masterCandidates) || !isLoaded(roomCandidates)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {_.differenceBy(masterCandidates, roomCandidates, "id")?.map(
              (item, key) => (
                <Select.Option key={key} value={item.id}>
                  {item.name}
                </Select.Option>
              )
            )}
          </Select>,
          <Dropdown
            overlay={
              <Menu>
                {actionMenuIcon(null, true).map((item: any) => (
                  <Menu.Item {...item}>{item.title}</Menu.Item>
                ))}
              </Menu>
            }
            disabled={_.isEmpty(selectedRows)}
          >
            <Button>
              {`Action ${
                selectedRows?.length ? `(${selectedRows?.length})` : ""
              }`}
              <DownOutlined />
            </Button>
          </Dropdown>,
          <Tooltip
            title={
              !isAnyCandidateEnabledViewDocument() &&
              "Applies to all candidates in this room"
            }
            placement={"bottom"}
          >
            <Popconfirm
              title="Are you sure want to stop all candidates from viewing the document?"
              onConfirm={onClickStopViewDocumentAll}
              okText="Yes"
              cancelText="No"
              disabled={!isAnyCandidateEnabledViewDocument()}
              placement={"bottom"}
            >
              <Button
                key="1"
                type={
                  isAnyCandidateEnabledViewDocument() ? `danger` : `primary`
                }
                onClick={() =>
                  !isAnyCandidateEnabledViewDocument() &&
                  onClickEnableViewDocumentAll()
                }
              >
                {`${
                  isAnyCandidateEnabledViewDocument() ? `Stop` : `Enable`
                } View Document`}
              </Button>
            </Popconfirm>
          </Tooltip>,
        ]}
        onBack={() =>
          history.push(
            generatePath(ASSESSOR_ROOMS, { project_slug: project_slug })
          )
        }
      >
        <Table
          scroll={{ x: "100%", y: 500 }}
          columns={columns}
          dataSource={tableData}
          actionBtnDropDown={actionMenuIcon}
          loading={!isLoaded(roomCandidates)}
          selectedRowsOnChange={(selected) => setSelectedRows(selected)}
          selectedRows={selectedRows}
          pagination={{
            total: tableData.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} candidates`,
          }}
        />
      </PageHeader>
    </div>
  );
};
