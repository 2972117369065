import {InputNumber, Modal} from "antd";
import * as style from "src/pages/Assessor/utils/GetPeriodSeconds/getPeriodModal.module.scss";
import * as React from "react";

export const getPeriodSeconds = async () =>
  new Promise<number>((resolve, reject) => {
    let minute = 1;
    Modal.confirm({
      title: "Set Period",
      width: 550,
      onCancel: () => {
        resolve(undefined);
      },
      onOk: () => {
        resolve(minute * 60);
      },
      content: (
        <div className={style.setPeriodModal}>
          <div>
            Please set the period to enable the document for the candidate to
            view.
          </div>
          <div
            className={`d-flex w-100 justify-content-center align-items-center pt-3 ${style.period}`}
          >
            <InputNumber
              placeholder={`Set here..`}
              size={"large"}
              min={1}
              defaultValue={1}
              value={minute}
              onChange={(value) => (minute = value)}
            />
            <div className={`ml-2`}>minute(s)</div>
          </div>
        </div>
      ),
    });
  });
