import {createModel} from "@rematch/core";

export interface CountModelState {
  count: number;
}

export const countModel = createModel<CountModelState>({
  state: {
    count: 0
  },
  reducers: {
    setCount: (state, newNumber) => {
        state.count = newNumber;
    }
  }
});
