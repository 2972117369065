// auth
export const LOGIN = "/login";
export const MIDDLEWARE = "/middleware";

// admin
export const PROJECT = "/:projectSlug";
export const PROJECT_CANDIDATE = `${PROJECT}/candidate`;

// candidate
export const CANDIDATE = `/candidate/:project_slug/:room_id`;
export const CANDIDATE_LANDING = `${CANDIDATE}/landing`;
export const CANDIDATE_VIEWER = `${CANDIDATE}/viewer`;

// assessor
export const ASSESSOR = "/assessor/:project_slug";
export const ASSESSOR_CANDIDATES = `${ASSESSOR}/candidates`;
export const ASSESSOR_ROOMS = `${ASSESSOR}/rooms`;
export const ASSESSOR_SPECIFIC_ROOM = `${ASSESSOR_ROOMS}/:room_id`;
