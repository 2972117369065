import * as React from "react";
import * as style from "./multiAddToRoom.module.scss";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Tag,
} from "antd";
import { CandidateRepo } from "src/models/repositories/candidate";
import { useParams } from "react-router-dom";
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { ICandidate, ICandidateList, IRoom } from "src/models/types";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { collections } from "src/constants/firestore";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import { RoomRepo } from "src/models/repositories/room";
import * as _ from "lodash";

interface MultiAddToRoomProps {
  roomRepo: RoomRepo;
  candidates: ICandidate[];
  visible: boolean;
  setVisible: any;
}

export const MultiAddToRoom: React.FC<MultiAddToRoomProps> = (props) => {
  const { roomRepo, candidates, visible, setVisible } = props;
  const { project_slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;

  useFirestoreConnect(() => [
    { collection: collections.projects },
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [{ collection: collections.rooms }],
      storeAs: "masterRooms",
    },
  ]);

  const masterRooms = useSelector<StoreInterface, IRoom[]>(
    (state) => state.firestore.ordered.masterRooms
  );

  return (
    <>
      <Modal
        visible={visible}
        title="Add To Existing Room"
        onCancel={() => setVisible(false)}
        okButtonProps={{ loading }}
        onOk={async () => {
          form
            .validateFields()
            .then(async (values) => {
              setLoading(true);
              message.loading({
                content: "Adding candidate(s) to room...",
                key: "assign_to_room",
              });

              await candidates.map(async (item) => {
                await roomRepo.addCandidateToRoom(values.roomId, item.id);
              });

              form.resetFields();

              setLoading(false);
              setVisible(false);
              message.success({
                content: "Successfully added candidate(s) to room.",
                key: "assign_to_room",
              });
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <div>
          Please select a room listed below to add the candidate(s).
          <div className={`${style.tags} py-2`}>
            {candidates?.map((item) => {
              return <Tag color={"geekblue"}>{item.name}</Tag>;
            })}
          </div>
        </div>
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="roomId"
            label="Room"
            rules={[
              {
                required: true,
                message: "Please choose one room.",
              },
            ]}
          >
            <Select placeholder={`Please choose a room here..`}>
              {masterRooms?.map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
