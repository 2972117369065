import { collections } from "src/constants/firestore";
import { IAssessor, ICandidate } from "src/models/types";
import { BaseRepo } from "src/models/baseRepo";


export class AssessorRepo extends BaseRepo {
  async createAssessor(assessor: IAssessor, checkExist = true) {
    const { email, ...assessorData } = assessor;
    const _email = email.toLowerCase();
    const assessorsPath = `${collections.projects}/${this.projectSlug}/${collections.assessors}`;
    if (checkExist) {
      const assessorSnap = await this.firestore.collection(assessorsPath)
        .where('email', '==', _email)
        .get();
      if (!assessorSnap.empty) {
        throw new Error(`Assessor email already exists.`);
      }
    }
    await this.firestore.add(assessorsPath, {email: _email, ...assessorData});
  }

  async updateAssessor(assessorId: string, assessor: IAssessor) {
    await this.firestore.update(`${collections.projects}/${this.projectSlug}/${collections.assessors}/${assessorId}`, {
      name: assessor.name,
    });
  }

  async deleteAssessor(assessorId: string) {
    await this.firestore.delete(`${collections.projects}/${this.projectSlug}/${collections.assessors}/${assessorId}`);
  }
}
