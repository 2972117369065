import * as React from "react";
import * as style from "./pdfViewer.module.scss";
import "./reactPdfOvverride.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { VariableSizeList } from "react-window";
import * as _ from "lodash";
import { useEffectAsync } from "src/utils/hooks";
import { Button, Spin, Tooltip } from "antd";
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  CompressOutlined,
} from "@ant-design/icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
  file: string;
}

const PAGE_SPACING = 20;

export const PdfViewer: React.FC<PdfViewerProps> = (props) => {
  const [numPages, setNumPages] = useState();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [cachedPageDimensions, setCachedPageDimensions] = useState();
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();
  const [scale, setScale] = useState<number>(0.5929982529229942);
  const [scaleLimit, setScaleLimit] = useState<number>();
  const [showControls, setShowControls] = useState<boolean>(false);

  const viewerContainerRef = useRef<HTMLDivElement>();
  const listRef = useRef<VariableSizeList>();
  const innerListRef = useRef<HTMLDivElement>();
  const handleWindowResize = _.debounce(() => {
    calculateContainerBounds();
  }, 300);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize, true);
    window.addEventListener("orientationchange", handleWindowResize, true);
    return () => {
      window.removeEventListener("resize", handleWindowResize, true);
      window.removeEventListener("orientationchange", handleWindowResize, true);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize, true);
    window.addEventListener("orientationchange", handleWindowResize, true);
    return () => {
      window.removeEventListener("resize", handleWindowResize, true);
      window.removeEventListener("orientationchange", handleWindowResize, true);
    };
  }, [handleWindowResize]);

  useEffectAsync(() => {
    setFitScale();
  }, [containerWidth, cachedPageDimensions]);

  const setFitScale = () => {
    if (cachedPageDimensions && containerWidth) {
      const page = cachedPageDimensions.get(currentPage)[2];
      const viewport = page.getViewport(
        containerWidth / page.getViewport(1.0).width
      );
      setScaleLimit(viewport.scale);
      const scale = viewport.scale <= 1 ? viewport.scale * 0.95 : 1;
      setScale(scale);
    }
  };

  /**
   * Load all pages to cache all page dimensions.
   */
  const cachePageDimensions = (pdf) => {
    const promises = Array.from(
      { length: pdf.numPages },
      (v, i) => i + 1
    ).map((pageNumber) => pdf.getPage(pageNumber));

    // Assuming all pages may have different heights. Otherwise we can just
    // load the first page and use its height for determining all the row
    // heights.
    Promise.all(promises).then((values) => {
      const pageDimensions = values.reduce((accPageDimensions, page) => {
        console.log();
        accPageDimensions.set(page.pageIndex + 1, [
          page.view[2],
          page.view[3] + PAGE_SPACING,
          page,
        ]);
        return accPageDimensions;
      }, new Map());

      setCachedPageDimensions(pageDimensions);
    });
  };

  const calculateContainerBounds = () => {
    if (viewerContainerRef.current) {
      const rect = viewerContainerRef.current.getBoundingClientRect();
      setContainerHeight(rect.height);
      setContainerWidth(rect.width);
    }
  };

  const recomputeRowHeights = () => {
    listRef.current && listRef.current.resetAfterIndex(0, true);
  };

  useEffectAsync(() => {
    recomputeRowHeights();
  }, [scale]);

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
    cachePageDimensions(pdf);
    calculateContainerBounds();
  };

  const updateCurrentVisiblePage = ({ visibleStopIndex }) => {
    setCurrentPage(visibleStopIndex + 1);
  };

  const getItemSize = (index) => {
    return cachedPageDimensions.get(index + 1)[1] * scale;
  };

  return (
    <div
      className={`w-100 h-100 p-relative d-flex justify-content-center align-items-center ${style.pdfViewer}`}
      ref={viewerContainerRef}
    >
      <Document
        file={props.file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.error(error)}
        loading={<Spin spinning={true} />}
      >
        <div
          className={`${style.controls} ${
            showControls && style.showControls
          } d-flex align-items-center`}
        >
          <div className={`w-100`}>{`${currentPage} / ${numPages}`}</div>
          <div className={`${style.toolbar}`}>
            <Tooltip title="Zoom In">
              <ZoomInOutlined
                className={style.toolbarIcon}
                onClick={() => {
                  scale < 4 && setScale((prevState) => prevState + 0.05);
                }}
              />
            </Tooltip>
            <Tooltip title="Reset">
              <CompressOutlined
                className={style.toolbarIcon}
                onClick={() => setFitScale()}
              />
            </Tooltip>
            <Tooltip title="Zoom Out">
              <ZoomOutOutlined
                className={style.toolbarIcon}
                onClick={() =>
                  scale > 0.05 && setScale((prevState) => prevState - 0.05)
                }
              />
            </Tooltip>
          </div>
        </div>
        {cachedPageDimensions && containerHeight && (
          <VariableSizeList
            height={containerHeight}
            itemCount={numPages}
            itemSize={getItemSize}
            overscanCount={2}
            onItemsRendered={updateCurrentVisiblePage}
            ref={listRef}
            width={containerWidth}
            innerRef={innerListRef}
            onScroll={({ scrollDirection, scrollOffset }) => {
              scrollOffset > 40 &&
                setShowControls(scrollDirection === "forward");
            }}
          >
            {({ style, index }) => {
              const pageNumber = index + 1;
              const pageDimensions = cachedPageDimensions.get(pageNumber);
              const width = pageDimensions[0];
              const styleAdditional = {
                ...style,
                width,
                marginTop: 40,
              };

              return (
                <div
                  style={styleAdditional}
                  key={`page_${pageNumber}`}
                  className={`p-relative d-flex ${
                    scale > scaleLimit
                      ? `justify-content-start`
                      : `justify-content-center`
                  } align-items-center w-100`}
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <Page
                    pageNumber={pageNumber}
                    scale={scale}
                    renderAnnotationLayer={false}
                    renderInteractiveForms={false}
                    renderTextLayer={false}
                    loading={<Spin spinning={true} />}
                  />
                </div>
              );
            }}
          </VariableSizeList>
        )}
      </Document>
    </div>
  );
};
