import * as React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import { FirebaseReducer, isEmpty, isLoaded } from "react-redux-firebase";
import { IUser } from "src/models/types";
import { useParams } from "react-router-dom";
import { message, Spin } from "antd";
import { useQueryString } from "src/utils/hooks";

interface RouteForCandidateProps {
  children: any;
}

export const RouteForCandidate: React.FC<RouteForCandidateProps> = (
  props: RouteForCandidateProps
) => {
  const { children } = props;
  const { project_slug, room_id } = useParams();

  const profile: any = useSelector<
    StoreInterface,
    FirebaseReducer.Profile<{}> | IUser
  >((state) => state.firebase.profile);

  const claims = profile?.token?.claims;
  const isCandidate = (claims?.cid) && (claims?.project_slug === project_slug) && (claims?.room_id === room_id);

  return isLoaded(profile) ? (
    !isEmpty(profile) && isCandidate ? (
      children
    ) : (
      <>
        {message.warn({
          content:
            "Unauthorized. Please contact your admin.",
          key: "unauthorized",
        })}
      </>
    )
  ) : (
    <div className={`d-flex h-100 justify-content-center align-items-center`}>
      <Spin tip="Loading..." />
    </div>
  );
};
