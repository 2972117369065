import * as React from "react";
import * as style from "./createRoomModal.modules.scss";
import { Button, Form, Input, InputNumber, message, Modal, Select } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { ICandidate, ICandidateList, IRoom } from "src/models/types";
import { collections } from "src/constants/firestore";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import { ModalProps } from "antd/es/modal";
import * as _ from "lodash";
import { useState } from "react";
import { useEffectAsync } from "src/utils/hooks";
import { RoomRepo } from "src/models/repositories/room";

interface CreateRoomModalProps extends ModalProps {
  toggle: () => void;
  editRoom?: IRoom;
  candidateIds?: string[];
}

export const CreateRoomModal: React.FC<CreateRoomModalProps> = (props) => {
  const { toggle, editRoom,candidateIds, ...otherProps } = props;

  const { project_slug } = useParams();
  const firestore = useFirestore();
  const roomRepo = new RoomRepo(firestore, project_slug);

  const [form] = Form.useForm();
  const [loading, setIsLoading] = useState<boolean>(false);

  useFirestoreConnect(() => [
    { collection: collections.materials },
    {
      collection: collections.materials,
      storeAs: "masterDocuments",
    },
    {
      collection: collections.projects,
      doc: project_slug,
      subcollections: [{ collection: collections.candidates }],
      storeAs: "masterCandidates",
    },
  ]);

  const masterDocuments = useSelector<StoreInterface, ICandidateList[]>(
    (state) => state.firestore.ordered.masterDocuments
  );

  const masterCandidates = useSelector<StoreInterface, ICandidate[]>(
    (state) => state.firestore.ordered.masterCandidates
  );

  useEffectAsync(() => {
    if (props.visible) {
      form.resetFields();
      if (editRoom) {
        form.setFieldsValue({
          name: editRoom.name,
          document: editRoom.materialIds[0],
          candidates: editRoom.candidateIds,
        });
      }
      if(candidateIds) {
        form.setFieldsValue({
          candidates: candidateIds,
        });
      }
    }
  }, [props.visible]);

  const onClickOk = async () => {
    try {
      const values: any = await form.validateFields();
      setIsLoading(true);
      message.loading({
        content: `${editRoom ? `Updating` : `Creating`} room...`,
        key: "create_room",
      });

      const details: IRoom = {
        name: values.name,
        materialIds: [values.document],
      };

      if (editRoom) {
        await roomRepo.updateRoom(editRoom.id, details);
      } else {
        const createdRoom = await roomRepo.createRoom(details);

        if (!_.isEmpty(values.candidates)) {
          await values.candidates.map(async (item) => {
            await roomRepo.addCandidateToRoom(createdRoom.id, item);
          });
        }
      }

      toggle();
      message.success({
        content: `Successfully ${editRoom ? `updated` : `created`} room`,
        key: "create_room",
      });
      setIsLoading(false);
    } catch (e) {
      if (e?.errorFields) {
        console.error("Validate Failed:", e);
        return;
      }
      message.error(`Error ${editRoom ? `updating` : `creating`} room`);
    }
  };

  return (
    <div className={style.createRoom}>
      <Modal
        title={`${editRoom ? `Edit` : `Create New`} Room`}
        okText={`${editRoom ? `Update` : `Create`}`}
        cancelText="Cancel"
        onCancel={() => toggle()}
        onOk={onClickOk}
        okButtonProps={{
          loading: loading,
        }}
        {...otherProps}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter the room's name",
              },
            ]}
          >
            <Input placeholder={`Enter room name..`} />
          </Form.Item>
          <Form.Item
            name="document"
            label="Document"
            rules={[
              {
                required: true,
                message: "Please choose the room's document.",
              },
            ]}
          >
            <Select
              showSearch={true}
              placeholder={"Choose a document.."}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {masterDocuments?.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="candidates" label="Candidates">
            <Select
              showSearch={true}
              placeholder={"Select the candidates to add to this room.."}
              mode={"multiple"}
              disabled={!!editRoom}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {masterCandidates?.map((item, index) => {
                return (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
