import {
  ExtendedAuthInstance,
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
  ExtendedStorageInstance,
} from "react-redux-firebase";
import { collections } from "src/constants/firestore";
import { IUser } from "src/models/types";
import { firestore as globalFirestore } from "firebase";
import { AssessorRepo } from "src/models/repositories/assessor";
import { message } from "antd";
import { matchPath } from "react-router";
import { CANDIDATE_LANDING } from "src/constants/route";

interface ILoginLinkParams {
  redirect_url?: string;
  token?: string;
  project_slug?: string;
  room_id?: string;
}

export class UserRepo {
  firebase: ExtendedFirebaseInstance &
    ExtendedAuthInstance &
    ExtendedStorageInstance;
  firestore: ExtendedFirestoreInstance;

  constructor(firebase, firestore: ExtendedFirestoreInstance) {
    this.firebase = firebase;
    this.firestore = firestore;
  }

  async createUser(email: string, password: string, name: string) {
    const _email = email.toLowerCase();
    const userCredential = await this.firebase
      .auth()
      .createUserWithEmailAndPassword(_email, password);
    const user = userCredential.user;
    await this.firestore.set(`${collections.users}/${user.uid}`, {
      email: _email,
      name: name,
    });
  }

  async getUser(email) {
    const userQuery = await this.firestore
      .collection(collections.users)
      .where("email", "==", email)
      .get();
    if (userQuery.empty) {
      return null;
    }
    return {
      id: userQuery.docs[0].id,
      ...userQuery.docs[0].data()
    } as IUser;
  }

  async getOrCreateUser(email: string, name: string) {
    // @ts-ignore
    await this.firebase.functions().httpsCallable("getOrCreateUser")({
      email,
      name,
    });
  }

  async updateUser(userId: string, user: IUser) {
    await this.firestore.update(`${collections.users}/${userId}`, user);
  }

  async addUserAsAssessor(user: IUser, projectSlug: string) {
    const assessorRepo = new AssessorRepo(this.firestore, projectSlug);
    await assessorRepo.createAssessor({
      name: user.name,
      email: user.email,
    });
    await this.firestore.update(`${collections.users}/${user.id}`, {
      assessorProjectIds: globalFirestore.FieldValue.arrayUnion(projectSlug),
    });
  }

  async sendLoginLinkToAssessor(
    email: string,
    name: string,
    projectSlug: string
  ) {
    // @ts-ignore
    await this.firebase.functions().httpsCallable("sendLoginLinkToAssessor")({
      projectSlug,
      email,
      name,
    });
  }

  async sendLoginLinkToCandidate(
    email: string,
    name: string,
    projectSlug: string,
    roomId: string,
    candidateId: string,
  ) {
    // @ts-ignore
    await this.firebase.functions().httpsCallable("sendLoginLinkToCandidate")({
      projectSlug,
      email,
      name,
      roomId,
      candidateId,
    });
  }

  async loginByLink(email: string, location: string, params?: ILoginLinkParams) {
    // assessor login by password-less link
    if (await this.firebase.auth().isSignInWithEmailLink(location)) {
      return await this.firebase.auth().signInWithEmailLink(email, location);
    }

    // candidate login by custom token
    if (params?.token) {
      const match = matchPath<ILoginLinkParams>(params.redirect_url, {
        path: CANDIDATE_LANDING,
        exact: true,
        strict: false
      });
      const { project_slug, room_id } = match?.params;
      // @ts-ignore
      const { data } = await this.firebase.functions().httpsCallable('generateCustomToken')({
        email, project_slug, room_id, token: params.token
      });

      await this.firebase.login({
        token: data.token,
        profile: {email}
      });
    }
  }

  async loginUserByCredential(email: string, password: string) {
    const { user } = await this.firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    return user;
  }

  async logoutUser() {
    this.firebase
      .logout()
      .then(() => {
        message.success({
          content: "Successfully logged out",
          key: "unauthorized",
        });
      })
      .catch((error) => {
        console.log("error", error.code, error.message);
      });
  }
}
