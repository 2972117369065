import { CandidateStatuses, collections } from "src/constants/firestore";
import { ICandidate, IRoom, IRoomCandidate } from "src/models/types";
import { firestore as globalFirestore } from "firebase";
import { BaseRepo } from "src/models/baseRepo";
import { UserRepo } from "src/models/repositories/user";
import { ExtendedFirebaseInstance } from "react-redux-firebase";

export class RoomRepo extends BaseRepo {
  mapCandidatesToRoom(
    roomCandidates: IRoomCandidate[],
    candidates: ICandidate[]
  ) {
    if (roomCandidates && candidates) {
      roomCandidates = roomCandidates?.map((candidate, key) => {
        const c = candidates?.find((x) => x.id === candidate.id);
        return {
          ...candidate,
          name: c?.name ?? null,
          email: c?.email ?? null,
          key: key,
        };
      });
    }
    return roomCandidates?.length > 0 ? roomCandidates : [];
  }

  mapRoomsToDocument(rooms: any, materials: any) {
    if (rooms && materials) {
      rooms = rooms?.map((data: any, key) => {
        const match = materials?.find(
          (doc: any) => data.materialIds && data.materialIds[0] === doc.id
        );
        return {
          ...data,
          document_name: match?.name ?? "-",
          key: key,
        };
      });
    }
    return rooms?.length > 0 ? rooms : [];
  }

  async createRoom(room: IRoom) {
    return await this.firestore.add(
      `${collections.projects}/${this.projectSlug}/${collections.rooms}`,
      room
    );
  }

  async updateRoom(roomId: string, room: IRoom) {
    await this.firestore.update(
      `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}`,
      room
    );
  }

  async deleteRoom(roomId: string) {
    await this.firestore.delete(
      `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}`
    );
  }

  async addCandidateToRoom(
    roomId: string,
    candidateId: string,
    config?: IRoomCandidate
  ) {
    const defaultConfig = {
      availableSec: 0,
      status: CandidateStatuses.OFFLINE,
    };

    await this.firestore.update(
      `${collections.projects}/${this.projectSlug}/${collections.candidates}/${candidateId}`,
      {
        roomIds: globalFirestore.FieldValue.arrayUnion(roomId),
      }
    );

    await this.firestore.update(
      `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}`,
      {
        candidateIds: globalFirestore.FieldValue.arrayUnion(candidateId),
      }
    );

    await this.firestore.set(
      `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}/${collections.candidates}/${candidateId}`,
      {
        ...defaultConfig,
        ...config,
      }
    );
  }

  async removeCandidateFromRoom(roomId: string, candidateId: string) {
    await this.firestore.delete(
      `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}/${collections.candidates}/${candidateId}`
    );

    await this.firestore.update(
      `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}`,
      {
        candidateIds: globalFirestore.FieldValue.arrayRemove(candidateId),
      }
    );

    await this.firestore.update(
      `${collections.projects}/${this.projectSlug}/${collections.candidates}/${candidateId}`,
      {
        roomIds: globalFirestore.FieldValue.arrayRemove(roomId),
      }
    );
  }

  async updateStatus(
    roomId: string,
    candidateId: string,
    status: CandidateStatuses
  ) {
    await this.firestore.update(
      `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}/${collections.candidates}/${candidateId}`,
      {
        status,
      }
    );
  }

  async setAccesses(roomId: string, candidateIds: string[], seconds: number) {
    const endDate = new Date();
    endDate.setSeconds(endDate.getSeconds() + seconds);
    const endTimestamp = globalFirestore.Timestamp.fromDate(endDate);

    for (const candidateId in candidateIds) {
      await this.firestore.update(
        `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}/${collections.candidates}/${candidateIds[candidateId]}`,
        {
          endAt: endTimestamp,
        }
      );
    }
  }

  async revokeAccesses(roomId: string, candidateIds: string[]) {
    for (const candidateId in candidateIds) {
      await this.firestore.update(
        `${collections.projects}/${this.projectSlug}/${collections.rooms}/${roomId}/${collections.candidates}/${candidateIds[candidateId]}`,
        {
          endAt: globalFirestore.FieldValue.delete(),
        }
      );
    }
  }

  async sendInvitations(
    firebase: ExtendedFirebaseInstance,
    roomId: string,
    selectedCandidates: ICandidate[]
  ) {
    const userRepo = new UserRepo(firebase, this.firestore);

    for (const candidate of selectedCandidates) {
      await userRepo.sendLoginLinkToCandidate(
        candidate.email,
        candidate.name,
        this.projectSlug,
        roomId,
        candidate.id
      );
    }
  }
}
