import * as React from "react";
import { Button, Result, Spin } from "antd";
import { useEffectAsync, useQueryString } from "src/utils/hooks";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { UserRepo } from "src/models/repositories/user";
import { PageBase } from "src/components/PageBase/PageBase";

interface MiddlewareProps {}

export const Middleware: React.FC<MiddlewareProps> = (props) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();
  const location = useLocation();
  const queryString = useQueryString();

  const [isError, setIsError] = useState<boolean>();

  const userRepo = new UserRepo(firebase, firestore);
  useEffectAsync(async () => {
    try {
      // always logout first to re-login, toast message wont be shown here
      await firebase.logout();

      await userRepo.loginByLink(
        decodeURIComponent(queryString.email),
        window.location.href,
        queryString,
      );

      if (queryString.redirect_url) {
        history.push({
          pathname: queryString.redirect_url,
          search: location.search,
        });
      } else {
        setIsError(true);
      }
    } catch (e) {
      console.error("Error signing: ", e);
      setIsError(true);
    }
  }, []);

  return (
    <PageBase isAssessor={false} showHeaderRightSection={false}>
      <div className={`d-flex h-100 justify-content-center align-items-center`}>
        {isError ? (
          <Result
            status="403"
            title="Link Expired"
            subTitle="Sorry, you are not authorized to access this page. Your link could be expired or used. Please contact your admin to obtain a new link."
            extra={
              <Button type="primary" onClick={() => history.push(`/`)}>
                Back Home
              </Button>
            }
          />
        ) : (
          <Spin tip="Please wait..." />
        )}
      </div>
    </PageBase>
  );
};
