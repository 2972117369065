import * as React from "react";
import * as style from "./landingPage.module.scss";
import { Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import generatePath from "src/utils/regex";
import { CANDIDATE_VIEWER } from "src/constants/route";
import { useSelector } from "react-redux";
import { StoreInterface } from "src/store";
import { FirebaseReducer } from "react-redux-firebase";
import { IUser } from "src/models/types";

interface LandingPageProps {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const history = useHistory();
  const params = useParams();

  const profile: any = useSelector<
    StoreInterface,
    FirebaseReducer.Profile<{}> | IUser
    >((state) => state.firebase.profile);
  const candidateId = profile?.token?.claims?.cid;

  return (
    <div
      className={`w-100 h-100 d-flex flex-column justify-content-start ${style.landingPage}`}
    >
      <div className={style.welcomeText}>Welcome,</div>
      <div className={style.content}>
        Please click continue and wait for the host to start the document.
      </div>
      <Button
        size={"large"}
        type={"primary"}
        onClick={() => {
          history.push({
            pathname: generatePath(CANDIDATE_VIEWER, { ...params }),
            search: `?cid=${candidateId}`,
          });
        }}
      >
        Continue
      </Button>
    </div>
  );
};

export default LandingPage;
